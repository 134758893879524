import React from 'react';
import Hero from './Hero'; // Import Hero component

function Home() {
  return (
    <div>
      <Hero /> {/* Hero is displayed only on the Home page */}

      {/* Video Section */}
      <div className="video-container">
        <video autoPlay muted loop className="homepage-video">
          <source src={`${process.env.PUBLIC_URL}/atwhomepage.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Main Content Section */}
      <div className="home-container">
        {/* Text Section */}
        <div className="home-text">
          <h2>Welcome to the Home Page</h2>
          <p>
            At AllTheWayUX, we take your user experience all the way—from the initial testing phase to seamless implementation. Our team of expert UX engineers combines innovative design strategies with the latest advancements in AI technology to create intuitive, engaging, and impactful digital experiences.
          </p>
          <p>
            We believe that exceptional UX isn’t just about design—it’s about understanding your users, refining every interaction, and delivering a product that truly resonates. Whether you’re launching a new platform or optimizing an existing one, AllTheWayUX is your partner in crafting user-centric solutions that drive success.
          </p>
          <p>
            Let us help you turn your vision into reality—all the way!
          </p>
        </div>

        {/* Image Section */}
        <div className="home-image">
          <img 
            src={`${process.env.PUBLIC_URL}/allthewaygraff.jpg`} 
            alt="All The Way Graffiti" 
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
