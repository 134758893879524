import React from 'react';

function About() {
  return (
    <div className="about-us">
      <h1 className="section-title">About AllTheWayUX</h1>
      <p>
        Welcome to <strong>AllTheWayUX</strong>, where we are dedicated to taking your user experience 
        <strong> all the way</strong>—from the first spark of an idea to the moment your product meets the 
        hands of your users. We specialize in creating seamless, user-centered digital experiences that 
        not only engage but also delight, setting your business apart in a competitive digital landscape.
      </p>

      <h2 className="section-subtitle"><strong>Our Mission</strong></h2>
      <p>
        At <strong>AllTheWayUX</strong>, our mission is simple: to empower businesses by delivering exceptional 
        user experiences. We believe that great UX goes beyond aesthetics—it’s about building meaningful 
        connections between your product and its users. Our approach ensures that every step of the user 
        journey is intuitive, efficient, and enjoyable, driving both user satisfaction and business growth.
      </p>

      <h2 className="section-subtitle"><strong>How We Work</strong></h2>
      <p>
        From the <strong>initial testing phase</strong> to <strong>final implementation</strong>, we guide you through every stage of the UX process. 
        Our team of seasoned UX engineers combines proven methodologies with cutting-edge <strong>AI technology</strong>, 
        ensuring that your platform is not just visually appealing but also intelligent, adaptable, and highly functional. 
        Whether you’re looking to create a new product or refine an existing one, we tailor our services to meet your unique needs and goals.
      </p>

      <h2 className="section-subtitle"><strong>Why Choose Us?</strong></h2>
      <ul className="features-list">
        <li><strong>Comprehensive Approach</strong>: We don’t just stop at designing interfaces. We dive deep into user research, prototyping, testing, and implementation to deliver end-to-end solutions.</li>
        <li><strong>AI-Driven Innovation</strong>: Leveraging the power of the latest <strong>AI technologies</strong>, we uncover insights that drive smarter decisions and more impactful designs.</li>
        <li><strong>Collaborative Partnership</strong>: At AllTheWayUX, we see ourselves as an extension of your team. Your success is our success, and we work closely with you to bring your vision to life.</li>
        <li><strong>Results That Matter</strong>: Our designs don’t just look good—they deliver measurable improvements in user engagement, conversion rates, and overall satisfaction.</li>
      </ul>

      <h2 className="section-subtitle"><strong>Our Process</strong></h2>
      <ol className="process-list">
        <li><strong>Discover</strong>: We start by understanding your users, your goals, and your challenges through in-depth research and analysis.</li>
        <li><strong>Design</strong>: Using insights from our research, we craft intuitive, user-friendly designs that align with your brand and resonate with your audience.</li>
        <li><strong>Test</strong>: Rigorous usability testing ensures that every detail of your product works seamlessly, providing the best possible experience.</li>
        <li><strong>Implement</strong>: Our team works closely with your developers to ensure that the designs are implemented flawlessly, bringing your vision to life.</li>
        <li><strong>Optimize</strong>: After launch, we analyze user feedback and behavior to refine and enhance your product continuously.</li>
      </ol>

      <h2 className="section-subtitle"><strong>Let’s Go All the Way Together</strong></h2>
      <p>
        When you partner with <strong>AllTheWayUX</strong>, you’re not just investing in design—you’re investing in a strategy that puts your users first 
        and ensures your digital platform stands out in today’s fast-paced world. Let us help you create a product that doesn’t just meet expectations but exceeds them—all the way.
      </p>
      <p className="contact-link">
        <a href="/contact">Let’s make it happen.</a>
      </p>
    </div>
  );
}

export default About;
