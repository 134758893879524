import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './Hero.css'; // Import the Hero-specific styles

function Hero() {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleButtonClick = () => {
    navigate('/about'); // Navigate to the "about" page
  };

  return (
    <section className="hero">
      <div className="hero-overlay">
        <h1>Enhance Your User Experience</h1>
        <p>We help you create seamless, engaging, and user-centered digital experiences.</p>
        <button className="hero-button" onClick={handleButtonClick}>
          Enhance Your UX
        </button>
      </div>
    </section>
  );
}

export default Hero;
